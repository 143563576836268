@import "variables";

.nos_services {
    margin-top: 120px;

    display: flex;
    flex-direction: column;
}

.nos_services__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

@media screen and (max-width: 1150px) {
    .nos_services__cards {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 700px) {
    .nos_services__cards {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (max-width: $maxWidthMobile) {
}

.nos_services__card {
    width: 273px;

    margin-top: 50px;

    padding-bottom: 5px;

    align-self: center;
    justify-self: center;

    display: flex;
    flex-direction: column;

    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.25);
    border-radius: 3px;

    .nos_services__card_image {
        width: 100%;
        height: 197px;
        width: 273px;

        margin-bottom: 18px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    h3 {
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;

        color: $primary;

        padding-left: 13px;
        margin-bottom: 6px;
    }

    .nos_services__card_description {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;

        color: #1d293f;

        padding: 0 13px;
        margin-bottom: 5px;

        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    .nos_services__card_more {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        padding: 0 13px;

        img {
            height: 22px;
            cursor: pointer;
        }
    }
}

.services_communes_container {
    margin-top: 50px;
}

.services_communes_title {
    font-weight: bold;
    color: $accent;
}

.services_communes {
    font-style: italic;
    color: #7c8087;
}
